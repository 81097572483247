import {mock, Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";

Random.extend({
    organizations: function () {
        return this.pick(getOrganizations())
    },
    agentStatus: function () {
        return this.pick(["INITIAL", "OFFLINE", "WORKING"])
    },
    matchStatus: function () {
        return this.pick(["INITIAL", "NOMATCH", "WORKING"])
    }
})

export let equipments = [];
for (let i = 0; i < 20; i++) {
    equipments[i] = {
        "id": i,
        "agentStatus": Random.agentStatus(),
        "agentUsername": Random.string(20),
        "alias": Random.ctitle(1, 4) + "盘",
        "autoPull": Random.boolean(),
        "defaulted": Random.boolean(),
        "deviceStatus": Random.agentStatus(),
        "extensionNo": Random.natural(1, 18),
        "matchStatus": Random.matchStatus(),
        "orgId": Random.organizations().id,
        "orgName": Random.organizations().name,
        "orgTaxNo": Random.natural(10) + Random.string(8),
        "auditor": Random.cname(),
        "agentPassword": Random.natural(100000000, 900000000),
        "contactChannel": Random.natural(10000000, 90000000),
        "contactPersons": Random.cname(),
        "electronicOrdinaryInvoiceLimit": 1000,
        "paperOrdinaryInvoiceLimit": 1000,
        "paperSpecialInvoiceLimit": 1000,
        "password": Random.natural(100000, 999999),
        "payee": Random.cname(),
        "payer": Random.cname()
    }
}

// 获取开票设备
const getListByPage = {
    url: "equipment/list",
    type: "post",
    response: config => {
        let list = equipments;
        // 条件筛选
        if (config.body.filter.orgId) {
            list = list.filter(i => i.orgId == config.body.filter.orgId)
        }
        if (config.body.filter.alias) {
            list = list.filter(i => new RegExp(config.body.filter.alias).test(i.alias))
        }
        const size = config.body.paging.size
        const current = config.body.paging.current;

        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }
        return res(true, {
            records: records,
            total: list.length
        })
    }
}

// 获取开票设备详情
const getDetail = {
    url: "/equipment/getDetail",
    type: "get",
    response: config => {
        const id = config.query.id;
        const detail = equipments[equipments.findIndex(i => i.id == id)];
        return res(true, detail)
    }
}
// 保存税盘
const saveEquipment = {
    url: "/equipment/saveEquipment",
    type: "post",
    response: config => {
        const equipment = config.body;
        if (equipment.id > -1) {
            const index = equipments.findIndex(i => i.id == equipment.id)
            equipments.splice(index, 1, equipment)
        } else {
            equipment.id = equipments.length
            equipments.push(equipment)
        }
        return res(true)
    }
}

export default [getListByPage, getDetail, saveEquipment]
