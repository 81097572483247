import {Random} from "mockjs";
import {res} from "./index";
import {getOrganizations, users} from "./login";
import {roles} from "./roleManage";

Random.extend({
    setRoles: function () {
        return this.pick([1, 2, 3, 4, 5])
    }
})

// 获取用户列表
const getListByPage = {
    url: "/user/getListByPage",
    type: "post",
    response: config => {
        const size = config.body.paging.size
        const current = config.body.paging.current;

        let list = users;
        if (config.body.filter.keyword) {
            list = users.filter(i => new RegExp(config.body.filter.keyword).test(i.nickName))
        }
        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }

        return res(true, {
            total: records.length,
            records: records
        })
    }
}

// 获取组织树
const getOrgTreeInScope = {
    url: "/organization/getOrgTreeInScope",
    type: "get",
    response: config => res(true, getOrganizations("tree"))
}

// 获取权限
const getRoles = {
    url: "/apis/role/getRoles",
    type: "get",
    response: config => res(true, roles)
}

// 获取用户信息
const getUser = {
    url: "/user/getUser",
    type: "get",
    response: config => {
        const id = config.query.userId;
        const user = users.filter(i => i.id == id);
        return res(true, user[0]);
    }
}

// 保存用户信息
const saveUser = {
    url: "/user/saveUser",
    type: "post",
    response: config => {
        const query = config.body;

        if (query.id) {
            const index = users.findIndex(i => i.id == query.id)
            users.splice(index, 1, query);
        } else {
            users.push(query);
        }
        return res(true, true)
    }
}

// 删除用户信息
const deleteUser = {
    url: "/user/delUser",
    type: "post",
    response: config => {
        const id = config.query.userId;
        users.splice(users.findIndex(i => i.id == id), 1);
        return res(true, true);
    }
}

export default [getListByPage, getOrgTreeInScope, getRoles,
    getUser, saveUser, deleteUser]