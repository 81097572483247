import {download, get, post} from './index.js'
import {res} from '../../mock'

export const ReqOrganizationTree = (params) => {
  return get('/organization/tree', params).then(res => res)
}

export const ReqOrganizationTagsTree = () => {
  return get('/organization/tagsTree').then((res) => res);
};
/**
 * 获取组织选择列表
 * @returns {Promise<any>} 返回组织选择列表
 * @constructor
 */
export const ReqOrganizationGetSelectList = () => {
  return get('/organization/getSelectList').then(res => res)
}
/**
 * 获取组织选择列表
 * @returns {Promise<any>} 返回组织选择列表
 * @constructor
 */
export const getManagerOrganizationTree = () => {
  return get('/organization/getManagerOrganizationTree').then(res => res)
}
/**
 * 组织校验
 * @param {*} data
 */
export const checkOrganization = (data) => {
    return post('/organization/checkOrganization', data).then(res => res)
}
/**
 * 组织编号校验
 * @param {*} data
 */
export const checkOrganizationUniqueCode = (data) => {
    return post('/organization/checkOrganizationUniqueCode', data).then(res => res)
}
/**
 * 获取paas 组织信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const getOrgPaasInfoTree = (data) => {
    return get('/organization/getOrgPaasInfoTree', data).then(res => res)
}
/**
 * 组织添加
 * @param {*} data
 */
export const saveOrganization = (data) => {
    return post('/tenant/addOrganization', data).then(res => res)
}

export const selectOrganization = (orgId) => {
    return get(`/organization/${orgId}`).then(res => res)
}

export const selectOrganizationTaxpayer = (orgId) => {
  return get(`/organization/taxpayer/${orgId}`).then(res => res)
}

export const ReqOrganizationGetOrgTreeInScope = () => {
  return get('/organization/getOrgTreeInScope').then(res => res)
}

// 校验组织编码唯一性
export const checkUniqueCode = {
  url: 'organization/checkUniqueCode',
  type: 'get',
  response: config => {
    const uniqueCode = config.query.uniqueCode
    const id = config.query.id
    return res(true, false)
  }
}

/**
 * 获取租户信息
 * @param data
 * @returns {Promise<*>}
 */
export const getTenant = () => {
  return get('/tenant/getCurrentTenant').then(res => res)
}
/**
 * 下载二维码
 * @returns {Promise<*>}
 */
export const downloadOrCode = () => {
  return download('/tenant/downloadOrCode').then(res => res)
}
