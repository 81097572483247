import {Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";

Random.extend({
    settlementStatus: function () {
        return this.pick(enumValue(enums.SettlementStatus))
    },
    billingType: function () {
        return this.pick(enumValue(enums.BillingType))
    },
    verifyStatus: function () {
        return this.pick(enumValue(enums.InvoiceVerifyStatus))
    },
    invoiceStatus: function () {
        return this.pick(enumValue(enums.VatInvoiceStatus))
    },
    organizations: function () {
        return this.pick(getOrganizations())
    }
})

// 结算单据列表
let settlementList = [
    {
        "id": 1,
        "dataId": "2020121310001",
        "settleTime": "2020-12-12",
        "buyerName": "北京多啦供应商有限公司",
        "buyerTaxNo": '91110114MA01G5Q98W',
        "buyerAddressTel": "北京市昌平区回龙观东大街众创空间 0521-8817187",
        "buyerBankAccount": "中国建设银行 571909691410801",
        "sellerName": '北京多啦零售商有限公司',
        "sellerTaxNo": '91110114MA01G5QQTM',
        "sellerAddressTel": '北京市昌平区回龙观东大街众创空间E座211 0521-8817187',
        "sellerBankAccount": '招商银行深圳分行营业部 755950541010902',
        "amount": "100",
        "taxAmount": "3",
        "sumAmount": "103",
        "settlementStatus": "FINISHED"
    },
    {
        "id": 2,
        "dataId": "2020121310002",
        "settleTime": "2020-12-12",
        "buyerName": "北京多啦财税科技有限公司",
        "buyerTaxNo": '91110114MA01G5Q98W',
        "buyerAddressTel": "北京市昌平区回龙观东大街众创空间 0521-8817187",
        "buyerBankAccount": "中国建设银行 571909691410801",
        "sellerName": '阿里未来酒店管理（浙江）有限公司',
        "sellerTaxNo": '91330110MA27XY00XD',
        "sellerAddressTel": Random.county(true) + " " + Random.natural(16619765837, 19920128294),
        "sellerBankAccount": Random.county(true) + Random.zip(),
        "amount": "3468.79",
        "taxAmount": "104.06",
        "sumAmount": "3572.85",
        "settlementStatus": "TO_UPLOAD_INVOICE"
    }
];

// 获取结算单列表
const getSettlementListByPage = {
    url: "/settlement/getSettlementListByPage",
    type: "post",
    response: config => {
        // 筛选条件
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = settlementList;

        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }

        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

// 获取结算单详情
const getSettlementById = {
    url: "/settlement/getSettlementById",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const index = settlementList.findIndex(i => i.id == id);
        return res(true, {
            detail: settlementList[index]
        })
    }
}

// 删除结算单
const deleteSettlementById = {
    url: "/settlement/deleteSettlementById",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const index = settlementList.findIndex(i => i.id == id);
        settlementList.splice(index, 1);
        return res(true, true)
    }
}

// 销货清单列表
let salesList = [{
    "id": 1,
    "merchandiseUniqueCode": "A10001",
    "merchandiseName": "酒水",
    "specification": "",
    "unit": "瓶",
    "quantity": "1",
    "price": "100",
    "taxRate": "0.03",
    "sumAmount": "103",
}];

// 获取销货清单列表
const getSalesListByPage = {
    url: "/settlementItem/getSalesListByPage",
    type: "post",
    response: config => {
        // 筛选条件
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = salesList;

        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }

        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

// 获取票单匹配表信息
const getInvoiceMatchingInfo = {
    url: "/settlement-billing-invoice/getInvoiceMatchingInfo",
    type: "get",
    response: config => {
        let list = invoiceList;

        // 实际金额
        let actualAmount = list.reduce((sum, item) => sum + item.sumAmount, 0);

        let matchingInfo = {
            invoiceNum: list.length,
            actualAmount: actualAmount,
            amountMatching:"符合"
        }

        return res(true, matchingInfo);
    }
}

// 发票列表
let invoiceList = [
    {
        "id": 1,
        "billingType": "Dzpp",
        "invoiceTime": "2020-12-12",
        "invoiceCode": Random.natural(1000000000, 9000000000),
        "invoiceNo": Random.natural(10000000, 90000000),
        "sumAmount": "103",
        "verifyStatus": "SUCCESS",
        "rise": true,
        "seal": false,
        "creditBank": true,
        "invoiceStatus": "ZC"
    },
    {
        "id": 2,
        "billingType": "Dzpp",
        "invoiceTime": "2020-12-12",
        "invoiceCode": Random.natural(1000000000, 9000000000),
        "invoiceNo": Random.natural(10000000, 90000000),
        "sumAmount": "103",
        "verifyStatus": "FAILURE",
        "rise": false,
        "seal": false,
        "creditBank": false,
        "invoiceStatus": "YC"
    },
    {
        "id": 3,
        "billingType": "Dzpp",
        "invoiceTime": "2020-12-12",
        "invoiceCode": Random.natural(1000000000, 9000000000),
        "invoiceNo": Random.natural(10000000, 90000000),
        "sumAmount": "103",
        "verifyStatus": "SUCCESS",
        "rise": true,
        "seal": true,
        "creditBank": true,
        "invoiceStatus": "ZC"
    }
];

// 获取发票列表
const getInvoiceListByPage = {
    url: "/settlement-billing-invoice/getInvoiceListByPage",
    type: "post",
    response: config => {
        // 筛选条件
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = invoiceList;


        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }

        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

export default [getSettlementListByPage, getSettlementById, deleteSettlementById, getSalesListByPage, getInvoiceMatchingInfo, getInvoiceListByPage];

