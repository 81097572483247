export default {
    namespaced: true,
    state: {
        /** 获取进项凭证 */
        get token() {
            return exLocalStorage.get('income-token');
        },
        /** 设置进项凭证 */
        set token(value) {
            let date = new Date();
            let times = date.getTime() + 20 * 60 * 1000;
            exLocalStorage.set('income-token', value, times);
        },
        //是否已登 true:已登 false:未登
        state: false,
        //进项企业信息
        etprs: {
            id: null,
            //名称
            name: '',//北京多啦财税科技有限公司
            //税号
            taxNo: '',//91110114MA01G5Q98W
            //凭证
            token: '',
            agentUsername: ''
        }
    }
};
const exLocalStorage = {
    set: function (key, value, times) {
        var data = {value: value, expired: new Date(times).getTime()};
        localStorage.setItem(key, JSON.stringify(data));
    },
    get: function (key) {
        var data = JSON.parse(localStorage.getItem(key));
        if (data == null) return null;
        if (data.expired != null && data.expired < new Date().getTime()) {
            localStorage.removeItem(key);
        } else {
            return data.value;
        }
    }
};