<template>
  <div class="select-button" :style="selectPosition">
    <div v-if="showStatus" class="arrow-button">
      <span v-if="status == 'down'" class="down" @click="handleDown">展开<i class="el-icon-arrow-down"></i></span>
      <span v-if="status == 'up'" class="up" @click="handleUp">收起<i class="el-icon-arrow-up"></i></span>
    </div>
    <div>
      <el-button type="default" @click="handleReset">重置</el-button>
      <el-button type="primary" @click="handleSelect">查询</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectButton',
  props: {
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectPosition () {
      return this.showStatus ? '' : 'justify-content: flex-end;'
    }
  },
  data () {
    return {
      status: 'down'
    }
  },
  methods: {
    handleDown () {
      this.status = 'up'
      this.$emit('list-show')
    },
    handleUp () {
      this.status = 'down'
      this.$emit('list-close')
    },
    handleReset () {
      this.$emit('reset')
    },
    handleSelect () {
      this.$emit('select')
    }
  }
}
</script>

<style scoped lang="scss">
  .select-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .arrow-button {
    color: #1890ff;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
  }

  .arrow-button:hover {
    cursor: pointer;
  }

  .el-button {
    padding: 0 16px;
  }
</style>