import {mock, Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";

Random.extend({
    organizations: function () {
        return this.pick(getOrganizations())
    },
    taxSign: function () {
        return this.pick([0, 1])
    },
    taxType: function () {
        return this.pick(["Default", "TAX_Exemption", "No_TAX"])
    },
    unit: function () {
        return this.pick(["个", "台", "量", "位", "品", "吨"])
    },
    taxRate: function () {
        return this.pick(0.00, 0.01, 0.03, 0.04, 0.05, 0.06, 0.09, 0.10, 0.13, 0.16, 0.17)
    },
    merchandiseCategory: function () {
        return this.pick(enumValue(enums.MerchandiseCategory))
    },
})


let merchandises = [];
for (let i = 0; i < 200; i++) {
    merchandises[i] = {
        "codeName": Random.string(2, 10),
        "id": i,
        "merchandiseName": Random.ctitle(4, 10),
        "orgId": Random.organizations().id,
        "price": Random.float(10, 10000, 0, 3),
        "specification": Random.ctitle(1, 4),
        "taxClassCode": Random.natural(1000000, 10000000),
        "taxClassName": Random.ctitle(2, 10),
        "taxRate": Random.taxRate(),
        "taxSign": Random.taxSign(),
        "taxType": Random.taxType(),
        "uniqueCode": Random.natural(10000, 99999),
        "unit": Random.unit(),
        "merchandiseCategory":Random.merchandiseCategory()
    }
}

// 获取商品列表
const getMerchandises = {
    url: "/merchandise/getRetailMerchandise",
    type: "post",
    response: config => {
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = merchandises;

        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }
        return res(true, {
            records: records,
            total: list.length
        })
    }
}

// 校验商品编码唯一性
const checkUniqueCode = {
    url: "merchandise/checkUniqueCode",
    type: "get",
    response: config => {
        const uniqueCode = config.query.uniqueCode;
        const id = config.query.id;
        return res(true, false)
    }
}

// 商品新增
const saveMerchandise = {
    url: "/merchandise/insertMerchandise",
    type: "post",
    response: config => {
        const merchandise = config.body;
        merchandise.id = merchandises.length
        merchandises.push(merchandise)
        return res(true)
    }
}

// 商品修改
const updateMerchandise = {
    url: "/merchandise/updateMerchandise",
    type: "post",
    response: config => {
        const merchandise = config.body;
        const index = merchandises.findIndex(i => i.id == merchandise.id)
        merchandises.splice(index, 1, merchandise)
        return res(true)
    }
}

// 批量修改版本号
const reviewVersion = {
    url: "/merchandise/reviewMerchandiseVersion",
    type: "post",
    response: config => res(true)
}
// 删除
const deleteMerchandise = {
    url: "/merchandise/deleteMerchandise",
    type: "get",
    response: config => {
        const id = config.query.id;
        const index = merchandises.findIndex(i => i.id == id);
        merchandises.splice(index, 1);
        return res(true, true)
    }
}

export default [getMerchandises, checkUniqueCode, saveMerchandise, updateMerchandise, reviewVersion,deleteMerchandise]