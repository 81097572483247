import {Random} from "mockjs";
import {res} from "./index";
import {getOrganizations} from "./login";

export let roles = [
    {
        "id":1,
        "roleId": 1,
        "level": 1,
        "dataScopeType": "AA",
        "desc": "超级管理员",
        "manger": null,
        "name": "超级管理员",
        "orgIds": [],
        "roleName": "超级管理员"
    },
    {
        "id":2,
        "roleId": 2,
        "level": 1,
        "dataScopeType": "AA",
        "desc": "这是备注",
        "manger": null,
        "name": "管理员",
        "orgIds": [],
        "roleName": "管理员"
    },
    {
        "id":3,
        "roleId": 3,
        "level": 1,
        "dataScopeType": "AA",
        "desc": "测试备注",
        "manger": null,
        "name": "测试",
        "orgIds": [],
        "roleName": "测试"
    },
    {
        "id":4,
        "roleId": 4,
        "level": 1,
        "dataScopeType": "CS",
        "desc": "自定义数据权限范围",
        "manger": null,
        "name": "研发",
        "orgIds": [1, 2, 5],
        "roleName": "研发"
    },
    {
        "id":5,
        "roleId": 5,
        "level": 1,
        "dataScopeType": "CS",
        "desc": "VP",
        "manger": null,
        "name": "运营",
        "orgIds": [1],
        "roleName": "运营"
    },
    {
        "id":6,
        "roleId": 6,
        "level": 1,
        "dataScopeType": "CS",
        "desc": "QA测试",
        "manger": null,
        "name": "QA",
        "orgIds": [1, 64],
        "roleName": "QA"
    },
    {
        "id":7,
        "roleId": 7,
        "level": 1,
        "dataScopeType": "CS",
        "desc": "开票资格",
        "manger": null,
        "name": "开票员",
        "orgIds": [2, 5],
        "roleName": "开票员"
    },
    {
        "id":8,
        "roleId": 8,
        "level": 1,
        "dataScopeType": "CS",
        "desc": "拥有协同权限",
        "manger": null,
        "name": "零售",
        "orgIds": [26],
        "roleName": "零售"
    },
    {
        "id":9,
        "roleId": 9,
        "level": 1,
        "dataScopeType": "CS",
        "desc": "拥有协同权限",
        "manger": null,
        "name": "供应商",
        "orgIds": [51, 54],
        "roleName": "供应商"
    }

];      // 权限列表

// 功能权限树
let scope = [
    {
        "checked": false,
        "children": [],
        "id": 1,
        "name": "首页",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [
            {
                "checked": false,
                "children": [],
                "id": 14,
                "name": "收票中心-手机扫描",
                "parentId": 2
            },
            {
                "checked": false,
                "children": [],
                "id": 15,
                "name": "收票中心-扫描仪扫描",
                "parentId": 2
            },
            {
                "checked": false,
                "children": [],
                "id": 16,
                "name": "收票中心-本地图片",
                "parentId": 2
            }
        ],
        "id": 2,
        "name": "收票中心",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 17,
                        "name": "发票管理-进项发票-导出",
                        "parentId": 6
                    }
                ],
                "id": 6,
                "name": "发票管理-进项发票",
                "parentId": 3
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 18,
                        "name": "发票管理-销项发票-导出",
                        "parentId": 7
                    }
                ],
                "id": 7,
                "name": "发票管理-销项发票",
                "parentId": 3
            },
            {
                "checked": false,
                "children": [],
                "id": 8,
                "name": "发票管理-同步日志",
                "parentId": 3
            }
        ],
        "id": 3,
        "name": "发票管理",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 19,
                        "name": "抵扣认证-旅客运输票据抵扣-导出",
                        "parentId": 9
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 20,
                        "name": "抵扣认证-旅客运输票据抵扣-全部勾选",
                        "parentId": 9
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 21,
                        "name": "抵扣认证-旅客运输票据抵扣-取消勾选",
                        "parentId": 9
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 22,
                        "name": "抵扣认证-旅客运输票据抵扣-确认抵扣",
                        "parentId": 9
                    }
                ],
                "id": 9,
                "name": "抵扣认证-旅客运输票据抵扣",
                "parentId": 4
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 23,
                        "name": "抵扣认证-旅客运输票据统计-导出",
                        "parentId": 10
                    }
                ],
                "id": 10,
                "name": "抵扣认证-旅客运输票据统计",
                "parentId": 4
            },
            {
                "checked": false,
                "children": [],
                "id": 11,
                "name": "抵扣认证-进项发票勾选抵扣",
                "parentId": 4
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 24,
                        "name": "抵扣认证-进项发票勾选统计-导出",
                        "parentId": 12
                    }
                ],
                "id": 12,
                "name": "抵扣认证-进项发票勾选统计",
                "parentId": 4
            }
        ],
        "id": 4,
        "name": "抵扣认证",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 25,
                        "name": "报销管理-报销列表-导出",
                        "parentId": 13
                    }
                ],
                "id": 13,
                "name": "报销管理-报销列表",
                "parentId": 5
            }
        ],
        "id": 5,
        "name": "报销管理",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 29,
                        "name": "基础设置-岗位管理-新增",
                        "parentId": 27
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 30,
                        "name": "基础设置-岗位管理-编辑",
                        "parentId": 27
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 31,
                        "name": "基础设置-岗位管理-删除",
                        "parentId": 27
                    }
                ],
                "id": 27,
                "name": "基础设置-岗位管理",
                "parentId": 26
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 32,
                        "name": "基础设置-用户管理-新增",
                        "parentId": 28
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 33,
                        "name": "基础设置-用户管理-编辑",
                        "parentId": 28
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 34,
                        "name": "基础设置-用户管理-删除",
                        "parentId": 28
                    }
                ],
                "id": 28,
                "name": "基础设置-用户管理",
                "parentId": 26
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 37,
                        "name": "基础设置-组织管理-新增",
                        "parentId": 35
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 38,
                        "name": "基础设置-组织管理-编辑",
                        "parentId": 35
                    }
                ],
                "id": 35,
                "name": "基础设置-组织管理",
                "parentId": 26
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 39,
                        "name": "基础设置-设备管理-新增",
                        "parentId": 36
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 40,
                        "name": "基础设置-设备管理-编辑",
                        "parentId": 36
                    }
                ],
                "id": 36,
                "name": "基础设置-设备管理",
                "parentId": 26
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 42,
                        "name": "基础设置-商品信息-新增",
                        "parentId": 41
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 43,
                        "name": "基础设置-商品信息-导入",
                        "parentId": 41
                    }
                ],
                "id": 41,
                "name": "基础设置-商品信息",
                "parentId": 26
            },
            {
                "checked": false,
                "id": 54,
                "name": "基础设置-发件邮箱",
                "parentId": 26,
                "children":[]
            }
        ],
        "id": 26,
        "name": "基础设置",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [],
        "id": 44,
        "name": "开票管理",
        "parentId": 0
    },
    {
        "checked": false,
        "children": [
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 48,
                        "name": "购销协同-销售方管理-手动添加",
                        "parentId": 46
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 49,
                        "name": "购销协同-销售方管理-批量导入",
                        "parentId": 46
                    }
                ],
                "id": 46,
                "name": "购销协同-销售方管理",
                "parentId": 45
            },
            {
                "checked": false,
                "children": [
                    {
                        "checked": false,
                        "children": [],
                        "id": 50,
                        "name": "购销协同-购买方管理-手动添加",
                        "parentId": 47
                    },
                    {
                        "checked": false,
                        "children": [],
                        "id": 51,
                        "name": "购销协同-购买方管理-批量导入",
                        "parentId": 47
                    }
                ],
                "id": 47,
                "name": "购销协同-购买方管理",
                "parentId": 45
            },
            {
                "checked": false,
                "children": [],
                "id": 52,
                "name": "购销协同-结算单据",
                "parentId": 45
            },
            {
                "checked": false,
                "children": [],
                "id": 53,
                "name": "购销协同-结算单详情",
                "parentId": 45
            }
        ],
        "id": 45,
        "name": "购销协同",
        "parentId": 0
    }
]

// 获取岗位列表
const getListByPage = {
    url: "apis/role/getListByPage",
    type: "post",
    response: config => {
        return res(true, {
            records: roles,
            total: roles.length
        })
    }
}

// 获取组织关系树
const getManagerOrganizationTree = {
    url: "/organization/getManagerOrganizationTree",
    type: "get",
    response: config => res(true, getOrganizations("tree"))
}

// 获取功能权限范围
const getFuncScope = {
    url: "/apis/role-func-scope/getFuncScope",
    type: "get",
    response: config => {
        const id = config.query.roleId;
        return res(true, {
            roleId: 1,
            scope: scope
        })
    }
}

// 新增岗位权限
const saveRole = {
    url: "apis/role/saveRole",
    type: "post",
    response: config => {
        config.body.id = roles.length;
        roles.push(config.body);
        return res(true, true)
    }
}

// 获取岗位权限
const getRole = {
    url: "apis/role/getRole",
    type: "get",
    response: config => {
        const id = config.query.id;
        const role = roles[roles.findIndex(i => i.id == id)]
        return res(true, role)
    }
}


export default [getListByPage, getManagerOrganizationTree, getFuncScope, saveRole, getRole]