import {res} from "./index";

let emailData = {
    emailProvider: "网易邮箱",
    emailAccount: "1223@qq.com",
    authorizationCode: "gfsgsg"
}
const getEmail = {
    url: "/email/getEmail",
    type: "get",
    response: config => res(true, emailData)
}
// 商品修改
const saveEmail = {
    url: "/email/saveEmail",
    type: "post",
    response: config => {
        emailData = config.body;
        return res(true)
    }
}
export default [saveEmail, getEmail]