import {mock, Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";

Random.extend({
    organizations: function () {
        return this.pick(getOrganizations())
    },
    taxLevel: function () {
        return this.pick(enumValue(enums.CompanyTaxLevel))
    },
    purType: function () {
        return this.pick(enumValue(enums.CooperateSellerPurType))
    },
    status: function () {
        return this.pick(enumValue(enums.CooperateSellerStatus))
    },
    taxRate: function () {
        return this.pick(0.00, 0.01, 0.03, 0.04, 0.05, 0.06, 0.09, 0.10, 0.13, 0.16, 0.17)
    }
})
let sellerList = [];
for (let i = 0; i < 200; i++) {
    const orgName=Random.organizations().name;
    sellerList[i] = {
        "id": i,
        "uniqueCode": Random.natural(10000, 99999),
        "shortName": orgName.substring(0,6),
        "name":  orgName,
        "taxNo": Random.natural(111111110000, 999999999999),
        "taxLevel": Random.taxLevel(),
        "purType": Random.purType(),
        "addressTel":Random.ctitle(10, 20),
        "bankAccount":Random.natural(1111110000, 999999999999),
        "remark":Random.ctitle(10, 20),
        "status":Random.status(),
        "address":Random.ctitle(10, 20),
        "enterTime":Random.date('yyyy-MM-dd')
    }
}
// 获取供用商列表
const getList = {
    url: "/cooperate-seller/getList",
    type: "post",
    response: config => {
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = sellerList;

        let records = [];
        if (list.length > (current * size)) {
            for (let i = (current - 1) * size; i < current * size; i++) {
                records.push(list[i]);
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                records.push(list[i])
            }
        }
        return res(true, {
            records: records,
            total: list.length
        })
    }
}
// 商品修改
const saveSellerDetail = {
    url: "/cooperate-seller/saveSellerDetail",
    type: "post",
    response: config => {
        const merchandise = config.body;
        const index = sellerList.findIndex(i => i.id == merchandise.id)
        sellerList.splice(index, 1, merchandise)
        return res(true)
    }
}
export default [getList,saveSellerDetail]