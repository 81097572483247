import {mock, Random} from "mockjs";
import {res} from "./index";
import {enums} from "./enums";
import {getOrganizations} from "./login";
import {enumValue} from "../src/util/enumUtil";

Random.extend({
    invoiceType: function () {
        return this.pick(enumValue(enums.InvoiceCenter))
    },
    organizations: function () {
        return this.pick(getOrganizations())
    },
    verifyStatus: function () {
        return this.pick(enumValue(enums.InvoiceVerifyStatus))
    },
    channel: function () {
        return this.pick(["PZ", "DD", "WX", "SM", "DD", "SMY"])
    }
})

const count = 50;
let invoices = [];
for (let i = 0; i < count; i++) {
    const amount = Random.float(0, 10000, 0, 3);
    const taxRate = Random.taxRate();
    const taxAmount = taxRate * amount;
    invoices[i] = {
        "amount": Random.float(0, 10000, 0, 3),
        "auditor": Random.cname(),
        "batchNo": Random.string(16),
        "buyerAddressTel": Random.county(true) + " " + Random.natural(16619765837, 19920128294),
        "buyerBankAccount": Random.county(true) + Random.zip(),
        "buyerName": Random.organizations().name,
        "buyerTaxNo": Random.organizations().taxNo,
        "channel": Random.channel(),
        "checkCode": "",
        "created": Random.date('yyyy-MM-dd'),
        "deduction": "0",
        "deleted": false,
        "departure": "",
        "destination": "",
        "details": [],
        "flight": "",
        "id": i,
        "imageInvoiceId": Random.natural(10),
        "imageUrl": Random.image(),
        "invoiceCode": Random.natural(111111110000, 999999999999),
        "invoiceNo": Random.natural(111111110000, 999999999999),
        "invoiceStatus": "0",
        "invoiceTime": Random.date('yyyy-MM-dd'),
        "invoiceType": Random.invoiceType(),
        "orgId": Random.organizations().id,
        "orgName": Random.organizations().name,
        "originInvoiceCode": "",
        "originInvoiceNo": "",
        "payee": "",
        "payer": "",
        "receiveTime": Random.date('yyyy-MM-dd'),
        "remark": Random.cparagraph(0, 3),
        "rider": "",
        "seatClass": "",
        "sellerAddressTel": Random.organizations().addressTel,
        "sellerBankAccount": Random.organizations().bankAccount,
        "sellerName": Random.ctitle(5, 12),
        "sellerTaxNo": Random.string(12),
        "source": "PC",
        "sumAmount": amount,
        "surcharge": "",
        "taxAmount": taxAmount,
        "tenantId": 1,
        "train21No": "",
        "uniqueCode": i,
        "updated": new Date(),
        "userId": i,
        "verifyCode": "",
        "verifyMessage": "",
        "verifyStatus": Random.verifyStatus(),
        "version": 2
    }
}

// 获取收票中心列表
const getInvoiceListByPage = {
    url: "/invoice/center/getInvoiceListByPage",
    type: "post",
    response: config => {
        const size = config.body.paging.size
        const current = config.body.paging.current;
        let list = invoices;

        // 筛选
        let records = [];
        for (let i = (current - 1) * size; i < current * size; i++) {
            records.push(list[i]);
        }

        return res(true, {
            records: records,
            total: list.length,
            size: size,
            current: current
        })
    }
}

// 获取发票图片
const getIncoiceImageById = {
    url: "invoice/center/getInvoiceImageById",
    type: "get",
    response: config => res(true, Random.dataImage('720x300', "Mock Data"))
}

// 获取发票详情
const selectInvoice = {
    url: "/invoice/center/selectInvoice",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const index = invoices.findIndex(i => i.id == id);
        const details = [
            {
                "amount": 32957.26000000,
                "created": "2020-11-30 15:47:11",
                "deleted": false,
                "general": "0.1g*20粒",
                "goodName": "非诺贝特胶囊(II)",
                "goodNum": 2000.00000000,
                "goodPrice": 16.47863248,
                "goodTypeNo": "",
                "id": 320,
                "invoiceId": 1725,
                "specifications": "盒",
                "taxAmount": 5602.74000000,
                "taxRate": 0.17000000,
                "updated": "2020-11-30 15:47:11",
                "version": 1
            },
            {
                "amount": 32957.26000000,
                "created": "2020-11-30 15:47:11",
                "deleted": false,
                "general": "0.1g*20粒",
                "goodName": "非诺贝特胶囊(II)",
                "goodNum": 2000.00000000,
                "goodPrice": 16.47863248,
                "goodTypeNo": "",
                "id": 320,
                "invoiceId": 1725,
                "specifications": "盒",
                "taxAmount": 5602.74000000,
                "taxRate": 0.17000000,
                "updated": "2020-11-30 15:47:11",
                "version": 1
            }
        ];
        return res(true, {
            invoice: invoices[index],
            invoiceDetails: details,
            reimbursement: null
        });
    }
}

// 获取扫描仪列表
const getScannerList = {
    url: "/scanner/getScannerList",
    type: "get",
    response: () => {
        return res(true, ['扫描仪一','扫描仪二']);
    }
}

// 获取批次编号
const getRecognizeBatchNo = {
    url: "/local/picture/getRecognizeBatchNo",
    type: "get",
    response: () => {
        return res(true, 'DLSM202012120001');
    }
}

// 发票识别
const recognize = {
    url: "/local/picture/recognize",
    type: "post",
    response: config => {
        return res(true, [
            {
                amount: "23452.12",
                batchIndex: "1",
                batchNo: "DLSM202012120006",
                channel: "BD",
                checkCode: "",
                created: "2020-12-12 14:03:45",
                deleted: false,
                id: 2623,
                image: "",
                imageUrl: "发票/2020-12/12/e248950c401f4c6a8a8bb019eccb1700.jpg",
                invoiceCode: "1100172130",
                invoiceId: 1,
                invoiceNo: "04370528",
                invoiceTime: "2017-12-15 00:00:00",
                invoiceType: "SpecInvoice",
                originalFile: "发票识别/原始文件/2020-12/12/9fbad870130049ea81e99bbcc38e9b6f.jpg",
                receiveTime: "2020-12-12 14:03:43",
                recognized: true,
                status: "1",
                submitted: false,
                tenantId: 1,
                updated: "2020-12-12 14:03:45",
                url: "http://collectticket-files-dev.oss-cn-zhangjiakou.aliyuncs.com/%E5%8F%91%E7%A5%A8/2020-12/12/e248950c401f4c6a8a8bb019eccb1700.jpg?Expires=1923113025&OSSAccessKeyId=LTAI4FiWA1tjaxq3rmPfp7gE&Signature=YwWhUZYyfaQWqV%2FgZZ2qQGA7oaQ%3D",
                userId: 1,
                version: 1,
            }
        ]);
    }
}

// 发票提交
const submit = {
    url: "/local/picture/submit",
    type: "post",
    response: () => {
        return res(true, true);
    }
}
export default [getInvoiceListByPage, getIncoiceImageById, selectInvoice, getRecognizeBatchNo, recognize, submit, getScannerList]
