export default {
    namespaced: true,
    state: {
        reimbursementId: "",
        reimbursementItemName: "",
    },
    getters: {
        getReimbursementInfo: state => {
            if (!state.reimbursementId) {
                state = JSON.parse(sessionStorage.getItem("reimbursementInfo"))
            }
            return state;
        }
    },
    actions: {},
    mutations: {
        setReimbursementInfo: (state, info) => {
            sessionStorage.setItem("reimbursementInfo", JSON.stringify(info));
            state.reimbursementId = info.reimbursementId;
            state.reimbursementItemName = info.reimbursementItemName
        },
        clearReimbursementInfo: (state) => {
            sessionStorage.removeItem("reimbursementInfo");
        }
    }
}