import axios from 'axios';
import {Message} from 'element-ui';
import store from '@/store/index';
import Vue from 'vue';

axios.defaults.timeout = 60000;
const MODE = process.env.NODE_ENV;
export const hostURL = MODE === 'production' ? location.origin : MODE === 'test' ? location.origin : '/api';

export const baseURL = MODE === 'production' ? `${hostURL}/api` : MODE === 'test' ? `${hostURL}/pdt-api` : hostURL;
axios.defaults.baseURL = baseURL;

// console.log('baseURL:', baseURL);

// export const websocketURL = (MODE === 'production' ?
//   'wss://' + location.host + '/wss' : (MODE === 'test' ?
//     'wss://' + location.host + '/wss' : 'ws://127.0.0.1:8888'))

const _axios = axios.create();
export default {baseURL: baseURL};

//白名单
const whiteList = ['/rpa/auth/login/'];

/**
 * http request 拦截器
 */
_axios.interceptors.request.use(
    (cfg) => {
      if(!cfg.headers) {
        cfg.headers = {};
      }

      // 用户授权凭证
      let token = store.state.token;
      if (token) {
        cfg.headers.Authorization = token;
      }

      // 协同结算角色
      if(cfg.url.startsWith('/coo/settlement/')){
        let cooRole = store.state.sys.cooRole;
        if(cooRole && cooRole != '') {
          cfg.headers.CooRole = cooRole;
        }
      }


    // get请求映射params参数
    if (cfg.method === 'get' && cfg.params) {
      let url = cfg.url + '?';
      for (const propName of Object.keys(cfg.params)) {
        const value = cfg.params[propName];
        let part = encodeURIComponent(propName) + '=';
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              let params = propName + '[' + key + ']';
              let subPart = encodeURIComponent(params) + '=';
              url += subPart + encodeURIComponent(value[key]) + '&';
            }
          } else {
            url += part + encodeURIComponent(value) + '&';
          }
        }
      }
      url = url.slice(0, -1);
      cfg.params = {};
      cfg.url = url;
    }

    return cfg;
  },
  (err) => {
    return Promise.reject(err);
  }
);

/**
 * 响应拦截器即异常处理
 */
_axios.interceptors.response.use(
  (rsp) => {
    console.debug('请求响应...', rsp);
    // 解析响应状态
    // console.log(rsp);
    if (rsp?.data?.code == 221) {
      let key = rsp?.headers?.redis_key;
      Vue.prototype.$msgTip.showTip({ content: key });
      return rsp;
    }
    const status = rsp.status;
    if (status < 200 || status >= 300) {
      Message({ message: parseStatus(status), type: 'error', duration: 5000 });
    } else {
      // 解析响应内容
      const res = rsp.data;
      if (res.success === undefined && res.code !== undefined && res.code !== 0) {
        Message({ message: res.msg, type: 'error', duration: 5000 });
      }
      if (res.success !== undefined && !res.success && res.code != '-1') {
        if (!(rsp.config.url && whiteList.indexOf(rsp.config.url) > -1)) {
          Message({message: res.message, type: 'error', duration: 5000});
        }
      }
    }
    return rsp;
  },
  (err) => {
    console.error('请求异常...', err);

    let { message } = err;
    if (message == 'Network Error') {
      message = '系统接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口连接超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    // let rsp = { data: { success: false, message: message } }

    // console.log("rsp.message",rsp.message)
    Message({ message: message, type: 'error', duration: 5000 });

    return Promise.reject(err);
  }
);

function parseStatus(status) {
  let message;
  switch (status) {
    case 400:
      message = '请求错误（400）';
      break;
    case 401:
      message = '请先登录（401）';
      break;
    case 403:
      message = '拒绝访问（403）';
      break;
    case 404:
      message = '请求出错（404）';
      break;
    case 405:
      message = '权限不足（405）';
      break;
    case 408:
      message = '请求超时（408）';
      break;
    case 500:
      message = '服务出错（500）';
      break;
    case 501:
      message = '网络错误（501）';
      break;
    case 502:
      message = '网关错误（502）';
      break;
    case 503:
      message = '服务不可用（503）';
      break;
    case 504:
      message = '网络超时（504）';
      break;
    case 505:
      message = 'HTTP版本不受支持（505）';
      break;
    default:
      message = `连接出错（${status}）`;
      break;
  }
  return message;
}

/**
 * get 请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    _axios
      .get(url, {
        params: params
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post 请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    _axios.post(url, data, config).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function request(config) {
  return new Promise((resolve, reject) => {
    _axios.request(config).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    _axios({
      url: url,
      data: data,
      method: 'put',
      params: params
    }).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装del请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    _axios({
      url: url,
      method: 'delete',
      data: data,
      params: params
    }).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * download 请求
 * @param url
 * @param data
 * @returns {Promise<unknown>}
 */
export function download(url, data = {}) {
  return new Promise((resolve, reject) => {
    _axios({
      method: 'post',
      url: url,
      data: data,
      responseType: 'blob'
    }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function downloadGet(url, data = {}) {
  return new Promise((resolve, reject) => {
    _axios({
      method: 'get',
      url: url,
      data: data,
      responseType: 'blob'
    }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
