export const enums = {
    "DataFlag": [
        {
            "label": "手工录入",
            "value": "SGLR"
        },
        {
            "label": "程序推送",
            "value": "CXTS"
        }
    ],
    "InvoiceDownloadStatus": [
        {
            "label": "等待下载",
            "value": "Waiting"
        },
        {
            "label": "正在下载",
            "value": "Downing"
        },
        {
            "label": "下载失败",
            "value": "Errored"
        },
        {
            "label": "下载成功",
            "value": "Success"
        }
    ],
    "CooperateStatus": [
        {
            "label": "未协同",
            "value": "NOCOOPERATE"
        },
        {
            "label": "已发送",
            "value": "SENTCOOPERATE"
        },
        {
            "label": "已协同",
            "value": "ALREADYCOOPERATE"
        },
        {
            "label": "已拒绝",
            "value": "REJECTCOOPERATE"
        }
    ],
    "InvoiceVerifyStatus": [
        {
            "label": "无需查验",
            "value": "NO_CHECK"
        },
        {
            "label": "查验中",
            "value": "CHECKING"
        },
        {
            "label": "查验成功",
            "value": "SUCCESS"
        },
        {
            "label": "查验失败",
            "value": "FAILURE"
        }
    ],
    "SettlementPreviewInvoiceRelationType": [
        {
            "label": "拆分单据",
            "value": "SplitBill"
        },
        {
            "label": "合并发票",
            "value": "MergeBill"
        },
        {
            "label": "拆分明细",
            "value": "SplitItem"
        },
        {
            "label": "合并明细",
            "value": "MergeItem"
        }
    ],
    "SettlementStatus": [
        // {
        //     "label": "待生成预制发票",
        //     "value": "TO_FORMULATE_INVOICE"
        // },
        // {
        //     "label": "待确认预制发票",
        //     "value": "TO_CONFIRM_SETTLEMENT"
        // },
        // {
        //     "label": "预制发票被回退",
        //     "value": "ROLLED_BACK"
        // },
        {
            "label": "待上传发票",
            "value": "TO_UPLOAD_INVOICE"
        },
        // {
        //     "label": "待确认发票信息",
        //     "value": "TO_CONFIRM_INVOICE"
        // },
        // {
        //     "label": "发票信息被驳回",
        //     "value": "REJECTED"
        // },
        {
            "label": "已完结",
            "value": "FINISHED"
        },
        // {
        //     "label": "已撤销",
        //     "value": "REVOCATION"
        // }
    ],
    "OrgAuditStatus": [
        {
            "label": "初始值",
            "value": "Initial"
        },
        {
            "label": "待审核",
            "value": "Pending"
        },
        {
            "label": "未通过",
            "value": "NotPass"
        },
        {
            "label": "已核实",
            "value": "Audited"
        }
    ],
    "InvoiceBizType": [
        {
            "label": "进项发票",
            "value": "Income"
        },
        {
            "label": "销项发票",
            "value": "Output"
        }
    ],
    "DeductIncomeVatSyncTopic": [
        {
            "label": "税款的所属期",
            "value": "HQDSSQ"
        },
        {
            "label": "未认证的发票",
            "value": "WRZDFP"
        },
        {
            "label": "已认证的发票",
            "value": "YRZDFP"
        },
        {
            "label": "未到期的发票",
            "value": "WDQDFP"
        },
        {
            "label": "发票详情信息",
            "value": "FPXQXX"
        },
        {
            "label": "往期抵扣统计",
            "value": "WQDKTJ"
        },
        {
            "label": "当期抵扣统计",
            "value": "DQDKTJ"
        }
    ],
    "InvoiceCenter": [
        {
            "label": "增值税专用发票",
            "value": "SpecInvoice"
        },
        {
            "label": "增值税普通发票",
            "value": "GenerInvoice"
        },
        {
            "label": "增值税电子普通发票",
            "value": "EleInvoice"
        },
        {
            "label": "增值税电子发票(通行费)",
            "value": "EleTollInvoice"
        },
        {
            "label": "增值税普通发票(卷票)",
            "value": "VolInvoice"
        },
        {
            "label": "机动车销售统一发票",
            "value": "VehInvoice"
        },
    ],
    "InvoiceType": [
        {
            "label": "增值税专用发票",
            "value": "SpecInvoice"
        },
        {
            "label": "增值税普通发票",
            "value": "GenerInvoice"
        },
        {
            "label": "增值税电子普通发票",
            "value": "EleInvoice"
        },
        {
            "label": "增值税电子发票(通行费)",
            "value": "EleTollInvoice"
        },
        {
            "label": "增值税普通发票(卷票)",
            "value": "VolInvoice"
        },
        {
            "label": "机动车销售统一发票",
            "value": "VehInvoice"
        },
        {
            "label": "定额发票",
            "value": "QuotaInvoice"
        },
        {
            "label": "飞 机 票",
            "value": "PlaneInvoice"
        },
        {
            "label": "火 车 票",
            "value": "TrainInvoice"
        },
        {
            "label": "客 运 票",
            "value": "PassengerInvoice"
        },
        {
            "label": "出租车票",
            "value": "TaxiInvoice"
        },
        {
            "label": "通用机打发票",
            "value": "GeneralMachineInvoice"
        },
        {
            "label": "过 路 费",
            "value": "TollInvoice"
        },
        {
            "label": "电子发票(普通发票)",
            "value": "FullEleGenerInvoice"
        },
        {
            "label": "电子发票(增值税专用发票)",
            "value": "FullEleSpecInvoice"
        }
    ],
    "VatInvoiceType": [
        {
            "label": "增值税专用发票",
            "value": "SPECIAL_INVOICE"
        },
        {
            "label": "增值税普通发票",
            "value": "GENERAL_INVOICE"
        },
        {
            "label": "增值税电子普通发票",
            "value": "ELE_GENERAL_INVOICE"
        },
        {
            "label": "增值税电子发票(通行费)",
            "value": "ELE_TOLL_INVOICE"
        },
        {
            "label": "增值税普通发票(卷票)",
            "value": "GENERAL_ROLL_INVOICE"
        },
        {
            "label": "机动车销售统一发票",
            "value": "VEHICLE_INVOICE"
        },
        {
            "label": "二手车销售统一发票",
            "value": "OLD_VEHICLE_INVOICE"
        }
    ],
    "AgentOnlineStatus": [
        {
            "label": "--",
            "value": "INITIAL"
        },
        {
            "label": "离线",
            "value": "OFFLINE"
        },
        {
            "label": "在线",
            "value": "WORKING"
        }
    ],
    "VatInvoiceStatus": [
        {
            "label": "正常",
            "value": "ZC"
        },
        {
            "label": "失控",
            "value": "SK"
        },
        {
            "label": "作废",
            "value": "ZF"
        },
        {
            "label": "冲红",
            "value": "CH"
        },
        {
            "label": "异常",
            "value": "YC"
        }
    ],
    "DeviceMatchStatus": [
        {
            "label": "--",
            "value": "INITIAL"
        },
        {
            "label": "不匹配",
            "value": "NOMATCH"
        },
        {
            "label": "已匹配",
            "value": "WORKING"
        }
    ],
    "DeviceOnlineStatus": [
        {
            "label": "--",
            "value": "INITIAL"
        },
        {
            "label": "未插入",
            "value": "OFFLINE"
        },
        {
            "label": "已插入",
            "value": "WORKING"
        }
    ],
    "SystemSource": [
        {
            "label": "菲住布渴",
            "value": "FZBK"
        }
    ],
    "InvoiceStatus": [
        {
            "label": "开具中",
            "value": "KJZ"
        },
        {
            "label": "已开具",
            "value": "YKJ"
        },
        {
            "label": "冲红中",
            "value": "CHZ"
        },
        {
            "label": "已冲红",
            "value": "YCH"
        },
        {
            "label": "作废中",
            "value": "ZFZ"
        },
        {
            "label": "已作废",
            "value": "YZF"
        },
        {
            "label": "开具失败",
            "value": "KJSB"
        },
        {
            "label": "作废失败",
            "value": "ZFSB"
        },
        {
            "label": "冲红失败",
            "value": "CHSB"
        }
    ],
    "ConfirmStatus": [
        {
            "label": '无需确认',
            "value": 'WXQR'
        },
        {
            "label":'已确认',
            "value": 'YQR'
        },
        {
            "label": '未确认',
            "value": 'WQR'
        },
        {
            "label": '已撤回',
            "value": 'YCH'
        },
        {
            "label": '已作废',
            "value": 'YZF'
        }
    ],
    "ToRedRemark": [
        {
            "label": '开票有误',
            "value": '1'
        },
        {
            "label":'销货退回',
            "value": '2'
        },
        {
            "label": '服务中止',
            "value": '3'
        },
        {
            "label": '销售折让',
            "value": '4'
        }
    ],
    "BillingSendMode": [
        {
            "label": "自取",
            "value": "Zq"
        },
        {
            "label": "邮寄",
            "value": "Kd"
        },
        {
            "label": "Email",
            "value": "Yj"
        },
        {
            "label": "短信",
            "value": "Dx"
        },
        {
            "label": "其它",
            "value": "Qt"
        }
    ],
    "BillingType": [
        {
            "label": "电子普票",
            "value": "Dzpp"
        },
        {
            "label": "纸质普票",
            "value": "Zzpp"
        },
        {
            "label": "纸质专票",
            "value": "Zzzp"
        },
        {
            "value": 'SGDP',
            "label": '收购电票'
        },
        {
            "value": 'SGZP',
            "label": '收购纸票'
        }
    ],
    "PrintingStatus": [
        {
            "label": "未打印",
            "value": "WDY"
        },
        {
            "label": "打印中",
            "value": "DYZ"
        },
        {
            "label": "已打印",
            "value": "YDY"
        },
        {
            "label": "--",
            "value": "BDY"
        },
        {
            "label": "打印失败",
            "value": "DYSB"
        }
    ],
    "ExpenseBillingStatus": [
        {
            value: "TO_BUILT",
            label: "待生成"
        },
        {
            value: "TRADE",
            label: "待开票"
        },
        {
            value: "ALREADY",
            label: "已开票"
        }
    ],
    "CollectStatus": [
        {
            value: "UNRECEIVED",
            label: "未收款"
        },
        {
            value: "RECEIVED",
            label: "已收款"
        }
    ],
    "CompanyTaxLevel": [
        {
            value: "GENERAL_TAXPAYER",
            label: "一般纳税人"
        },
        {
            value: "SMALL_SCALE_TAXPAYER",
            label: "小规模纳税人"
        }
    ],
    "CooperateSellerPurType": [
        {
            value: "FARM_SELLER",
            label: "农产供用商"
        },
        {
            value: "PURCHASE_SELLER",
            label: "采购供用商"
        }
    ],
    "CooperateSellerStatus": [
        {
            value: "DHZ",
            label: "待合作"
        },
        {
            value: "YHZ",
            label: "已合作"
        },
        {
            value: "YDJ",
            label: "已冻结"
        },
        {
            value: "QLZ",
            label: "清理中"
        },
        {
            value: "YQL",
            label: "已清理"
        }
    ],
    "EmailProvider": [
        {
            value: "EMAIL126",
            label: "网易邮箱"
        },
        {
            value: "EMAILQQ",
            label: "腾讯邮箱"
        }
    ],
    "MerchandiseCategory": [
        {
            value: "CARGO",
            label: "货物类"
        },
        {
            value: "EXPENSE",
            label: "费用类"
        }
    ],
    "SettlementAuditStatus": [
        {
            value: "AU",
            label: "--"
        },
        {
            value: "AI",
            label: "稽核中"
        },
        {
            value: "AF",
            label: "稽核失败"
        },
        {
            value: "AS",
            label: "稽核成功"
        }
    ]
}
