/**
 * 格式化枚举值
 * @param enumState 枚举定义
 * @param enumValue 枚举的值
 * @returns {*} 枚举名称
 * @constructor
 */
export const FmtEnum = (enumState = [], enumValue) => {
    const enumItem = enumState.find((item) => item.value == enumValue);
    if (enumItem) return enumItem.label;
    return "--";
};

export const enumValue = arg => {
    return arg.map(i => i.value);
}