export default {
    /** 获取Token */
    get token() {
        return localStorage.getItem('token');
    },
    /** 设置Token */
    set token(value) {
        localStorage.setItem('token', value);
    },
    /** 获取Enums */
    get enums() {
        return JSON.parse(localStorage.getItem('enums'));
    },
    /** 设置Enums */
    set enums(value) {
        localStorage.setItem('enums', JSON.stringify(value));
    },
    deductAuthorize:{},
    breadCrumbList: [],
    online: false,
    routerLoaded: false,
    isCollapse: false,
    loginExpired: false
};
