export default {
    namespaced:true,
    state: {
        get cooperateType() {
            return localStorage.getItem('cooperateType') ? localStorage.getItem("cooperateType") : "buyer";
        },
        set cooperateType(val){
            localStorage.setItem("cooperateType", val);
        }
    }
}