import {res} from "./index";
import {getOrganizations} from "./login";

// 待办
const todoList = {
    url: "/system/getToDoListInfo",
    type: "get",
    response: config => res(true, {
        totals: 13,
        unread: 2
    })
}

// 获取组织详情
const getOrganizationDetail = {
    url: "/organization/",
    type: "get",
    response: config => {
        const id = config.url.split("/")[config.url.split("/").length - 1];
        const orgs = getOrganizations();
        const org = orgs[orgs.findIndex(i => i.id == id)]
        return res(true, org)
    }
}

// 创建组织
const saveOrganization = {
    url:"/organization/saveOrganization",
    type:"post",
    response: config =>{
        config.body.id = getOrganizations().length;
        getOrganizations().push(config.body);
        return res(true)
    }
}

export default [todoList, getOrganizationDetail, saveOrganization];