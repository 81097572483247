import { get, post } from "@/service/index.js";

// 查询字典数据
export const getDicts = (params) => {
  return get("/system/dict/data/type/" + params).then((res) => res);
};

// 批量查询字典数据
export const batchGetDicts = (params) => {
  return get("/system/dict/data/batch/type", params).then((res) => res);
};

// 查询用户列设置
export const getColumnSetting = (params) => {
  return get("/user/columns/" + params).then((res) => res);
};

/**
 * 新增/更新用户列设置
 *
 * @param key 表格名
 * @param value 列设置信息
 * @returns {Promise<unknown>}
 */
export const upInsertColumnSetting = (key, value) => {
  let data = {
    tableName: key,
    value: value,
  };
  return post("/user/columns", data).then((res) => res);
};
